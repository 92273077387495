import AppConfigJson from "../../appconfig.json";
import DefaultResourceJson from "../assets/default-assetdb-resources.json";

const coreConfig = {

    values:{},

    initPerformed: false,

    /** Reads all defined values in config.json ready to be used. */
    Init() {
        if (this.initPerformed === true){
            return;
        }
        // Apply default resources
        this.ApplyValues(DefaultResourceJson);
        // Apply/Overwrite default key values from process.env if defined
        this.ApplyValues(this.ReadProcessEnvValues(Object.keys(DefaultResourceJson)));
        // Apply/Overwrite app config values (if defined, will overwrite defaults if specified)
        this.ApplyValues(AppConfigJson);

        this.initPerformed = true;
    },
    Get(psKey) {
        this.Init();
        return this.values[psKey]
    },

    /** reads process.env values based on specified keys */
    ReadProcessEnvValues(keys){
        const obj = {};
        for (const element of keys) {
            const value = process.env[element];
            if (value){
                obj[element] = value;
            }
        }
        return obj;
    },
    ApplyValues(obj){
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                if (obj[key] && ("$" + key !== obj[key])) {
                    this.values[key] = obj[key];
                }
            }
        }
    }
};

const ConfigValues = {

    /**
     * Get the environment variable for the specified key.
     * Returns either from process.env or from config-json depending on hosted solution (cloud/premise/dev).
     * @param {string} psKey Key
     */
    Get(psKey) {
        const configJsonValue = coreConfig.Get(psKey);
        return (configJsonValue ? configJsonValue : process.env[psKey]);
    },

    /** Get the environment variable for the specified key as string. */
    GetStr(psKey){
        const value = this.Get(psKey);
        if (typeof value === "string" || value instanceof String) // check string
            return value;
        if (value && value.toString) // check toString exist
            return value.toString();
        return value;
    },

    /** Get the environment variable for the specified key as bool. */
    GetBool(psKey){
        const value = this.Get(psKey);
        return (value === true || value === "true" || value === "1"); // explicit check
    },

    /** Get the environment variable for the specified key as number. */
    GetNr(psKey){
        return Number(this.Get(psKey));
    },

    /** Checks the VUE_APP_ON_PREMISE value and returns true if set to 1, otherwise false */
    get onPremise(){
        return this.GetBool("VUE_APP_ON_PREMISE");
    },

    /** log all defined values (not process.env) */
    Log() {
        console.log(coreConfig.values);
    }
};

export default ConfigValues;

import axios from "axios";
import Api from "../utils/api";
import ConfigValues from "../utils/configValues";

export default {
    _user: null,
    _basicLoggedIn: false,

    /** returns the user for the basic auth login. 
     * returns null if not logged in. */
    getUser() {
        return this._user;
    },

    /** gets if the user is logged in with basic auth */
    loggedIn() {
        if (ConfigValues.GetBool("VUE_APP_API_BASIC_ALLOW_SESSION_STORAGE") === true) {
            this.checkBasicAuthInSession();
        }
        return this._basicLoggedIn === true;
    },

    /** gets the tenant-id used by basic auth */
    getTenantID() {
        return sessionStorage.getItem("tenant-id");
    },

    /** Call a function to test the BasicAuthentication identification if passed for basic auth. 
     * @param tenantId tenant id to be used for on-premise with basic auth. This is not currently provided by the API.
    */
    performBasicAuthLogIn(user, pass, tenantId){
        return new Promise((resolve, reject) => {
            const instance = axios.create({
            auth: { username: user, password: pass }
            });

            instance.post(ConfigValues.Get("VUE_APP_API_BASIC_LOGIN_TEST_ENDPOINT"))
            .then(() => {
                this.setBasicAuthAuthentication(true, user, pass, tenantId);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
        });
    },

    /** 
     * Internal function.
     * Restores basic auth from the sessionStorage if available when Basic authentication scheme is enabled, returns true if successful.
     * This is automatically called from loggedIn.
     */
    checkBasicAuthInSession(){
        try {
            const itemValue = sessionStorage.getItem("asset-basic-user");
            if (itemValue){
            const basicData = JSON.parse(Buffer.from(itemValue, "base64").toString());
            if (basicData.user && basicData.cred && basicData.tenant) {
                this._basicLoggedIn = true;
                this._user = basicData.user;
                Api.defaults.headers.common["Authorization"] = "Basic " + basicData.cred;
                sessionStorage.setItem("tenant-id", basicData.tenant);
                return true;
            }
            }
        } catch (e) {
            console.error(e);
        }

        return false;
    },

    /** set basic auth authentication information to be used in the application when Basic authentication scheme is enabled.
     * @param success true if basic auth is enabled.
     * @param username username/email to be used for the basic user for the rest of the app if success is set to true.
     * @param tenant tenant ID to store to localstorage for getTenatnID() to return.
     */
    setBasicAuthAuthentication(success, username, password, tenant){
        this._basicLoggedIn = success === true;
        if (this._basicLoggedIn){
            this._user = { email: username };
            // configure main Api to use BasicAuthentication by setting default header to Authorization Basic for the remaining lifetime of the application
            Api.defaults.headers.common["Authorization"] = "Basic " + Buffer.from(username + ":" + password).toString("base64");
            sessionStorage.setItem("tenant-id", tenant);
        } else {
            this._user = null;
            Api.defaults.headers.common["Authorization"] = "";
            sessionStorage.removeItem("tenant-id");
        }

        if (ConfigValues.GetBool("VUE_APP_API_BASIC_ALLOW_SESSION_STORAGE") === true) {
            if (this._basicLoggedIn) {
                try {
                    sessionStorage.setItem("asset-basic-user", Buffer.from(JSON.stringify({ 
                        user: this._user, 
                        tenant: tenant, 
                        cred: Buffer.from(username + ":" + password).toString("base64") 
                    })).toString("base64"));
                } catch (e) {
                    console.error(e);
                }
            } else {
                sessionStorage.removeItem("asset-basic-user");
            }
        }
    },

    /** log out the basic auth user, resetting credentials. */
    logOut(){
        this.setBasicAuthAuthentication(false, null, null, null);
    }
}
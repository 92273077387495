<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
      >
        <router-view />
      </component>
    </div>
    <DxLoadPanel
      v-model:visible="getLoader"
      :show-indicator="true"
      :position="{ of: '#root' }"
      :show-pane="true"
      :shading="true"
      :close-on-outside-click="false"
      :height="100"
      :width="500"
      shading-color="rgba(0,0,0,0.4)"
    />
  </div>
</template>

<script>
import AppFooter from "./components/app-footer";
import { sizes, subscribe, unsubscribe } from "./utils/media-query";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import store from "./store";
import { en } from "devextreme/localization/messages/en.json";
import service from "./TranslationData";
import { locale, loadMessages } from "devextreme/localization";
import ApiAuth from "./utils/apiAuth";
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed
} from "vue";
import * as dxOverlay from "devextreme/ui/overlay";

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes["screen-x-small"],
    isLarge: screenSizes["screen-large"],
    cssClasses: Object.keys(screenSizes).filter(cl => screenSizes[cl])
  };
}

export default {
  components: {
    AppFooter,
    DxLoadPanel
  },
  setup() {
    const vm = getCurrentInstance();

    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {}});
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged () {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ["assetdb-app"].concat(screen.getScreenSizeInfo.cssClasses);
    });

    ApiAuth.setupApiInterceptors();

    dxOverlay.baseZIndex(5000006);

    return {
      title,
      screen,
      cssClasses
    };
  },
  data() {
    return {
      locales: service.getLocales(),
    };
  },
  computed: {
    getLoader() {
      return store.getters.getLoader;
    }
  },
  created() {
    this.locale = this.getLocale();
    this.initMessages();
    locale(this.locale);
  },
  methods: {
    getLocale() {
      const storageLocale = localStorage.getItem("locale");
      return storageLocale != null ? storageLocale : "en";
    },
    initMessages() {
      loadMessages(en);
      loadMessages(service.getDictionary());
    },
  }
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  width: 100%;
  flex: 1 0 auto;
}

* {
  box-sizing: border-box;
}

.assetdb-app {
    display: flex;
  height: 100%;
  width: 100%;
}

#app {
  @import "./themes/generated/variables.base.scss";
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>

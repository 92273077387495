import { getPermissionsByRole } from "@/authsrc/permissions";
import Keycloak from "keycloak-js";
import ConfigValues from "../utils/configValues";
import { UserRoleEnum } from "@swg/api";

export default {

    /**
     * interface KeycloakProfile {
     *     id?: string;
     *     username?: string;
     *     email?: string;
     *     firstName?: string;
     *     lastName?: string;
     *     enabled?: boolean;
     *     emailVerified?: boolean;
     *     totp?: boolean;
     *     createdTimestamp?: number;
     * }
     */
    _profile: null,

    /** Undocumented in Keycloak */
    _userInfo: null,

    /** gets the Keycloak instance */
    instance: null,

    _permissions: getPermissionsByRole(null),

    _role: null,

    /**
     * Wraps around the vue app bootstrapping process to use Keycloak authentication, prompting the user to log in if not yet authorized.
     *
     * Uses options from VUE_APP_KC_AUTHORITY_AUTH_URL | VUE_APP_KC_AUTHORITY_REALM | VUE_APP_KC_AUTHORITY_APP
     *
     * https://www.keycloak.org/securing-apps/vue
     * @returns Promise returning the Keycloak instance (which can also be imported and accessed using KC.instance)
     */
    setup() {
        return new Promise((resolve, reject) => {

            const initOptions = {
                url: ConfigValues.Get("VUE_APP_KC_AUTHORITY_AUTH_URL"),
                realm: ConfigValues.Get("VUE_APP_KC_AUTHORITY_REALM"),
                clientId: ConfigValues.Get("VUE_APP_KC_AUTHORITY_APP")
            }

            if (!initOptions.url || !initOptions.realm || !initOptions.clientId){
                reject(new Error("KC_AUTHORITY environment variables not set up!"));
                return;
            }

            const keycloak = Keycloak(initOptions);
            this.instance = keycloak;

            keycloak.init({ onLoad: "login-required" }).then(async (auth) => {
                if (!auth) {
                    window.location.reload();
                } else {
                    // load user profile before resolving, making it available in the app
                    await this.loadUserProfile();
                    await this.loadUserInfo();
                    this.loadRoleAndPermissions(keycloak.tokenParsed.resource_access, initOptions.clientId);
                    resolve(keycloak);
                }

                //Token Refresh
                setInterval(() => {
                    keycloak.updateToken(70).then((refreshed) => {
                        if (refreshed) {
                            console.log("Token refreshed" + refreshed);
                        }
                        // else {
                        //     console.warn('Token not refreshed, valid for '
                        //     + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                        // }
                    }).catch(() => {
                        console.error("Failed to refresh token");
                    });
                }, 6000)

            }).catch((e) => {
                console.error("Authenticated Failed");
                reject(e);
            });

        });

    },

    loggedIn() {
        if (this.instance){
            return this.instance.authenticated;
        }
        return false;
    },

    logOut(){
        if (this.instance) {
            this.instance.logout().then(() => {
                console.log("Logged out!");
            });
        }
    },

    /** constructs an Asset user based on the current loaded profile from the Keycloak authentication server */
    getUser(){
        if (this._profile && this._profile.email){
            return { email: this._profile.email };
        } else if (this._profile && this._profile.firstName){
            return { email: this._profile.firstName };
        } else if (this._profile && this._profile.id){
            return { email: "Keycloak user " + this._profile.id };
        }
        return { email: "Keycloak user" };
    },

    /** gets 'tenant_id' from the parsed token / user profile.
     * This is configured in Keycloak's client mappers. */
    getTenantID(){
        if (this.instance && this.instance.tokenParsed && this.instance.tokenParsed["tenant_id"]){
            return this.instance.tokenParsed["tenant_id"];
        }
        if (this._userInfo && this._userInfo["tenant_id"]){
            return this._userInfo["tenant_id"];
        }
        return "";
    },

    getPermissions() {
        return this._permissions;
    },

    getRole() {
        return this._role;
    },

    /**
     * Loads user info (undocumented in Keycloak).
     * @returns A promise, returning loaded user on success.
     */
    loadUserInfo() {
        return new Promise((resolve, reject) => {
            if (this.instance) {
                this.instance.loadUserInfo().then((result) => {
                    this._userInfo = result;
                    resolve(result);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            } else {
                reject(new Error("Keycloak instance not created."));
            }
        });
    },

    loadRoleAndPermissions(resourceAccess, clientId) {
      const pickMostHighestUserRole = (): string => {
          const highestRole = resourceAccess[clientId].roles.reduce((prev, curr) => {
              if (UserRoleEnum[curr] > prev.roleValue) {
                  prev = { roleValue: UserRoleEnum[curr], roleName: curr };
              }
              return prev;
          }, { roleValue: -1 });
          return highestRole.roleName;
      }

      this._role = pickMostHighestUserRole();
      this._permissions = getPermissionsByRole(UserRoleEnum[this._role]);
    },

    /**
     * Loads the user's profile.
     * @returns A promise, returning loaded profile on success.
     */
    loadUserProfile(){
        return new Promise((resolve, reject) => {
            if (this.instance) {
                this.instance.loadUserProfile().then((result) => {
                    this._profile = result;
                    resolve(result);
                }).catch((error) => {
                    console.error(error);
                    reject(error);
                });
            } else {
                reject(new Error("Keycloak instance not created."));
            }
        });
    },

    /** The user id. */
    get subject() {
        return (this.instance ? this.instance.subject : "");
    },

    /**
    * The base64 encoded token that can be sent in the Authorization header in requests to services.
    */
    get token() {
        return (this.instance ? this.instance.token : "");
    },

    /**
    * The base64 encoded ID token.
    */
    get idToken() {
        return (this.instance ? this.instance.idToken : "");
    }
};


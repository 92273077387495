
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxForm,
  DxItem,
  DxLookup,
  DxPopup,
  DxToolbar
} from "devextreme-vue/data-grid";
import DxButton from "devextreme-vue/button";
import { formatMessage } from "devextreme/localization";
import CustomStore from "devextreme/data/custom_store";
import { UserRoleEnum } from "@swg/api";
import { userAPI } from "@/utils/api";
import notify from "devextreme/ui/notify";
import auth from "@/authsrc/auth";
import { defineComponent } from "vue";
import ConfigValues from "../../utils/configValues";

const loadRoles = (items) => {
  let enums = [];
  for (let item in items) {
    enums.push({ id: items[item], name: item.toString(), visible: true });
  }
  return enums;
};

export default defineComponent({
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxPopup,
    DxToolbar,
    DxItem,
    DxLookup,
    DxForm,
    DxButton
  },
  data() {
    return {
      users: new CustomStore({
        load: async () => {
          return userAPI.getUsers()
            .then((response) => {
              return response.users;
            })
            .catch((error) => {
              console.error(error);
              return Promise.resolve([]);
            });
        },
        update: async (data, updateData) => {
          let allData = { ...data, ...updateData };
          return userAPI.editUser({ editUserRequest: { user: allData }});
        },
        insert: async (data) => {
          return userAPI.addUser({ addUserRequest: { user: data }})
            .then(() => {
              notify(formatMessage("Success"), "success", 5000);
            })
            .catch((error) => {
              console.error(error);
              notify(formatMessage("Error"), "error", 5000);
            });
        },
        remove: async (selected) => {
          return userAPI.deleteUser({
            userId: selected.id
          })
            .catch((error) => {
              console.error(error);
            });
        }
      }),
      labels: {
        popupLabel: ""
      },
      roles: loadRoles(UserRoleEnum),
      editingMode: false,
      allowInviteAccept: this.getAllowInviteAccept(),
      canReinvite: false,
      currentEditingRowId: undefined,
      permissions: auth.getPermissions()
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
    onInitNewRow(event) {
      this.editingMode = false;
      this.canReinvite = false;
      this.labels.popupLabel = formatMessage("GridUsersInviteUser");
      event.data = {
        ...event.data,
        role: UserRoleEnum.Viewer,
        active : true,
      }
    },
    onEditingStart(event) {
      this.editingMode = true;
      this.canReinvite = !event.data.emailVerified;
      this.currentEditingRowId = event.data.id;
      this.labels.popupLabel = formatMessage("GridUsersEditUser");
    },
    reinvite() {
      userAPI.verifyUserEmail({
        userId: this.currentEditingRowId
      })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllowInviteAccept() {
      const authType = ConfigValues.Get("VUE_APP_API_AUTH_TYPE");
      return authType != "B2C";
    },
  }
});

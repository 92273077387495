import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxPatternRule = _resolveComponent("DxPatternRule")!
  const _component_DxValidator = _resolveComponent("DxValidator")!
  const _component_DxTextBox = _resolveComponent("DxTextBox")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxSelectBox = _resolveComponent("DxSelectBox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createTextVNode(_toDisplayString(_ctx.getTranslate('ReusableNameLabel')) + ": ", 1),
      _createElementVNode("div", null, [
        _createVNode(_component_DxTextBox, {
          value: _ctx.defaultValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.defaultValue) = $event)),
          class: "edit-view-name",
          placeholder: _ctx.getTranslate('ViewListNamePlaceholder'),
          "value-change-event": "input",
          onEnterKey: _ctx.onSubmit,
          onValueChanged: _ctx.onValueChanged
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxValidator, { onValidated: _ctx.onValidated }, {
              default: _withCtx(() => [
                _createVNode(_component_DxPatternRule, {
                  pattern: _ctx.pattern,
                  message: _ctx.getTranslate('ViewListNamePatternRule')
                }, null, 8, ["pattern", "message"])
              ]),
              _: 1
            }, 8, ["onValidated"])
          ]),
          _: 1
        }, 8, ["value", "placeholder", "onEnterKey", "onValueChanged"]),
        _createVNode(_component_DxButton, {
          text: _ctx.acceptButtonTextInternal,
          class: "accept-btn",
          type: "success",
          disabled: !_ctx.valueIsValid,
          onClick: _ctx.onSubmit
        }, null, 8, ["text", "disabled", "onClick"])
      ])
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_DxSelectBox, {
        "data-source": _ctx.viewListNames,
        class: "choose-view",
        placeholder: _ctx.getTranslate('DataGridSelectedViewListPlaceholder'),
        "search-enabled": "true",
        value: _ctx.viewListSelected,
        onValueChanged: _ctx.raiseViewSelected
      }, null, 8, ["data-source", "placeholder", "value", "onValueChanged"]),
      _createVNode(_component_DxButton, {
        text: _ctx.getTranslate('DeleteView'),
        class: "delete-btn",
        type: "danger",
        disabled: !_ctx.seletedView,
        onClick: _ctx.onDelete
      }, null, 8, ["text", "disabled", "onClick"]),
      _createVNode(_component_DxButton, {
        text: _ctx.getTranslate('UpdateView'),
        class: "update-btn",
        type: "success",
        disabled: !_ctx.seletedView,
        onClick: _ctx.onUpdate
      }, null, 8, ["text", "disabled", "onClick"]),
      _createVNode(_component_DxButton, {
        text: _ctx.getTranslate('ClearView'),
        class: "clear-btn",
        type: "redo",
        disabled: !_ctx.seletedView,
        onClick: _ctx.onClear
      }, null, 8, ["text", "disabled", "onClick"])
    ])
  ], 64))
}
<template>
  <div class="content-block">
    <footer class="footer">
      {{ copyright }} © 2011-{{ new Date().getFullYear() }}
      Service Works Global <br>
      {{ footer }}
    </footer>
  </div>
</template>
<script>
import { formatMessage } from "devextreme/localization";

export default {
  data() {
    return {
      footer: "",
      copyright: ""
    };
  },
  created() {
    this.footer = formatMessage("AppFooterLabelFooterLabel");
    this.copyright = formatMessage("AppFooterCopyrighLabel");
  },
};
</script>
<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";

.footer {
  display: block;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 20px;
  padding-bottom: 24px;
}
</style>

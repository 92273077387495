import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxPopup = _resolveComponent("DxPopup")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxForm = _resolveComponent("DxForm")!
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.getTranslate("ListUsers")), 1),
    _createVNode(_component_DxDataGrid, {
      id: "usersListGrid",
      "data-source": _ctx.users,
      "show-borders": false,
      "column-resizing-mode": 'nextColumn',
      "column-min-width": 150,
      "column-auto-width": true,
      onInitNewRow: _ctx.onInitNewRow,
      onEditingStart: _ctx.onEditingStart
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxColumn, {
          "data-field": "email",
          caption: _ctx.getTranslate('GridUsersEmail')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "firstName",
          caption: _ctx.getTranslate('GridUsersFirstName')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "lastName",
          caption: _ctx.getTranslate('GridUsersLastName')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "creationDate",
          caption: _ctx.getTranslate('GridUsersCreationDate')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "emailVerified",
          caption: _ctx.getTranslate('GridUsersEmailVerified'),
          visible: _ctx.allowInviteAccept
        }, null, 8, ["caption", "visible"]),
        _createVNode(_component_DxColumn, {
          "data-field": "active",
          caption: _ctx.getTranslate('GridUsersActive')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "role",
          caption: _ctx.getTranslate('GridUsersRole')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxLookup, {
              "data-source": _ctx.roles,
              "value-expr": "id",
              "display-expr": "name"
            }, null, 8, ["data-source"])
          ]),
          _: 1
        }, 8, ["caption"]),
        _createVNode(_component_DxEditing, {
          mode: "popup",
          "allow-updating": _ctx.permissions.users.canEdit,
          "allow-deleting": _ctx.permissions.users.canDelete,
          "allow-adding": _ctx.permissions.users.canCreate
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxPopup, {
              "show-title": true,
              title: _ctx.labels.popupLabel,
              "max-width": 500,
              "max-height": 720
            }, null, 8, ["title"]),
            _createVNode(_component_DxForm, { "col-count": 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "email",
                  visible: _ctx.editingMode,
                  "editor-options": {disabled: true}
                }, null, 8, ["visible"]),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "email",
                  visible: !_ctx.editingMode,
                  "editor-options": {disabled: false}
                }, null, 8, ["visible"]),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "firstName"
                }),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "lastName"
                }),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "role"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxLookup, {
                      "data-source": _ctx.roles,
                      "value-expr": "id",
                      "display-expr": "name"
                    }, null, 8, ["data-source"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_DxItem, {
                  "col-span": 6,
                  "data-field": "active",
                  caption: _ctx.getTranslate('GridUsersActive'),
                  visible: _ctx.editingMode
                }, null, 8, ["caption", "visible"]),
                _createVNode(_component_DxItem, { "col-span": 6 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxButton, {
                      class: "button",
                      text: _ctx.getTranslate('GridUsersReinvite'),
                      visible: _ctx.allowInviteAccept && _ctx.canReinvite,
                      onClick: _ctx.reinvite
                    }, null, 8, ["text", "visible", "onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["allow-updating", "allow-deleting", "allow-adding"]),
        _createVNode(_component_DxToolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_DxItem, {
              options: {text: _ctx.getTranslate('GridUsersInviteUser')},
              name: "addRowButton",
              visible: _ctx.allowInviteAccept,
              "show-text": "always"
            }, null, 8, ["options", "visible"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data-source", "onInitNewRow", "onEditingStart"])
  ]))
}
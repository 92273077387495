import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "content-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.getTranslate("ListPermissions")), 1),
    _createVNode(_component_DxDataGrid, {
      id: "permissionsListGrid",
      "data-source": _ctx.permissions,
      "show-borders": false,
      "column-resizing-mode": 'nextColumn',
      "column-min-width": 150,
      "column-auto-width": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxColumn, {
          "data-field": "name",
          caption: _ctx.getTranslate('GridPermissionsName')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "viewer",
          caption: _ctx.getTranslate('GridPermissionsViewer')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "editor",
          caption: _ctx.getTranslate('GridPermissionsEditor')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "admin",
          caption: _ctx.getTranslate('GridPermissionsAdmin')
        }, null, 8, ["caption"])
      ]),
      _: 1
    }, 8, ["data-source"])
  ]))
}
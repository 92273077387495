
import { parameterAPI } from "@/utils/api";
import { formatMessage } from "devextreme/localization";
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxFilterPanel,
  DxFilterRow,
  DxHeaderFilter,
  DxPager,
  DxPaging,
  DxScrolling,
  DxSearchPanel,
  DxStringLengthRule,
  DxToolbar
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import auth from "@/authsrc/auth";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";
import { DxForm, DxItem, DxLabel, DxRequiredRule } from "devextreme-vue/form";
import { DxLookup } from "devextreme-vue/lookup";
import * as _ from "lodash";
import DataSource from "devextreme/data/data_source";
import notify from "devextreme/ui/notify";
import { parseHttpErrorMessage } from "@/utils/parse-http-error-message";
import { DxColorBox } from "devextreme-vue/color-box";
import { ParameterGroup } from "@swg/api/src/models/ParameterGroup";
import { Parameter, UpsertParameterGroup } from "@swg/api";

const position = "bottom center";
const direction = "up-push";
const defaultColor = "#9ED7DF";
type PopupMode = "Add" | "Edit";

export default {
  components: {
    DxColumn,
    DxDataGrid,
    DxFilterPanel,
    DxFilterRow,
    DxHeaderFilter,
    DxItem,
    DxPager,
    DxPaging,
    DxSearchPanel,
    DxToolbar,
    DxEditing,
    DxForm,
    DxRequiredRule,
    DxStringLengthRule,
    DxToolbarItem,
    DxPopup,
    DxLookup,
    DxLabel,
    DxButton,
    DxColorBox,
    DxScrolling
  },
  data() {
    return {
      dataSource: new DataSource({
        store: new CustomStore({
          load: async () => {
            try {
              const response = await parameterAPI.getParameterGroups();
              return response.parameterGroups;
            } catch (error) {
              console.error(error);
            }
          },
          remove: async (selected: ParameterGroup) => {
            if (!selected) {
              return;
            }

            const parameterGroupId = selected.parameterGroupId;

            try {
              await parameterAPI.deleteParameterGroup({ parameterGroupId: parameterGroupId });
            } catch (error) {
              console.error(error);
            }
          }
        })
      }),
      addParameterButtonOptions: {
        text: formatMessage("CreateParameterGroup"),
      },
      labels: {
        createEditParameterGroupLabel: formatMessage("CreateParameterGroup"),
        nameLengthValidationMessage: formatMessage("CreateEditParameterGroupNameLengthMessage", "50")
      },
      permissions: auth.getPermissions(),
      availableParameters: new Array<Parameter>(),
      allParameters: new Array<Parameter>(),
      formData: {} as ParameterGroup,
      popupVisible: false,
      popupMode: "Add" as PopupMode,
      assignedParameters: new Array<Parameter>(),
      selectedParameterId: "",
      selectedColor: defaultColor as string
    }
  },
  computed: {
    form: function () {
      return this.$refs.form.instance;
    },
    addBtnOptions: function () {
      return {
        text: this.getTranslate("Add"),
        disabled: !this.selectedParameterId,
        onClick: () => {
          const parameter = this.availableParameters.find(p => p.parameterId === this.selectedParameterId);
          this.assignedParameters.push(parameter);
          this.assignedParameters = _.orderBy(this.assignedParameters, p => p.name);
          this.availableParameters = this.availableParameters.filter(p => p.parameterId !== this.selectedParameterId);
          this.selectedParameterId = "";
        }
      }
    }
  },
  methods: {
    availableParameterChanged($event): void {
      this.selectedParameterId = $event.value;
    },
    colorValueChanged($event): void {
      this.selectedColor = $event.value;
    },
    getTranslate(text): string {
      return formatMessage(text);
    },
    async getAvailableParameters(): Promise<void> {
      this.allParameters = (await parameterAPI.getAllParameters())
        .filter(p => !!p.parameter)
        .map(p => p.parameter);

      const parameterGroups = (await parameterAPI.getParameterGroups()).parameterGroups;
      const parametersInGroups = [].concat(...parameterGroups?.map(g => g.parametersList ?? []) ?? []);
      const availableParameters = [];
      for (const p of this.allParameters) {
        if (!parametersInGroups.some(g => g.parameterId === p.parameterId)) {
          availableParameters.push(p);
        }
      }
      this.availableParameters = _.orderBy(availableParameters, p => p.name);
    },
    onToolbarPreparing(e): void {
      let toolbarItems = e.toolbarOptions.items;

      // customize addRow toolbar button
      for (let i = 0; i < toolbarItems.length; i++) {
        let item = toolbarItems[i];
        if (item.name === "addRowButton") {
          item.options.onClick = this.addClick;
          break;
        }
      }
    },
    showPopup(popupMode: PopupMode, data: ParameterGroup): void {
      this.formData = data;
      this.popupMode = popupMode;
      this.popupVisible = true;
      this.getAvailableParameters();
    },
    addClick(): void {
      this.showPopup("Add", {});
      this.labels.createEditParameterGroupLabel = formatMessage("CreateParameterGroup");
      this.assignedParameters = [];
      this.selectedColor = defaultColor;
    },
    editClick(e): void {
      const parameter = e.row.data as ParameterGroup;
      this.assignedParameters = _.orderBy(parameter.parametersList ?? [], p => p.name);
      this.selectedColor = parameter.color;
      this.showPopup("Edit", { ...e.row.data });
      this.labels.createEditParameterGroupLabel = formatMessage("EditParameterGroup");
    },
    deleteAssignedParameter(e): void {
      const parameterId = e.row.data.parameterId;
      this.assignedParameters = this.assignedParameters.filter(p => p.parameterId !== parameterId);
      const parameter = this.allParameters.find(p => p.parameterId === parameterId);
      if (parameter) {
        this.availableParameters.push(parameter);
        this.availableParameters = _.orderBy(this.availableParameters, p => p.name);
      }
    },
    async confirmClick(): Promise<void> {
      let result = this.form.validate();
      if (result.isValid) {
        let addUpdateParameterGroupPromise: Promise<UpsertParameterGroup>;
        this.assignedParameters.forEach(function(p){
          if (Object.hasOwnProperty.call(p, "tenantId")){
            delete p.tenantId
          }})
        if (this.popupMode === "Add") {
          addUpdateParameterGroupPromise = this.addParameterGroup();
        } else if (this.popupMode === "Edit") {
          addUpdateParameterGroupPromise = this.updateParameterGroup();
        }
        await addUpdateParameterGroupPromise
          .then(() => {
            this.dataSource.reload();
            this.popupVisible = false;
            notify(formatMessage("Success"), "success", 5000);
          }).catch(ex => {
            this.handleError(ex);
          });
      }
    },
    addParameterGroup(): Promise<UpsertParameterGroup> {
      return parameterAPI.createParameterGroup({
        upsertParameterGroup: {
          parameterGroup: {
            parametersList: this.assignedParameters,
            color: this.selectedColor,
            name: this.formData.name
          }
        }
      });
    },
    updateParameterGroup(): Promise<UpsertParameterGroup>{
      return parameterAPI.updateParameterGroup({
        upsertParameterGroup: {
          parameterGroup: {
            parameterGroupId: this.formData.parameterGroupId,
            parametersList: this.assignedParameters,
            color: this.selectedColor,
            name: this.formData.name
          }
        }
      });
    },
    cancelClick(): void {
      this.popupVisible = false;
    },
    async handleError(ex: any): Promise<void> {
      console.error("An error has occurred.", ex);
      let message = formatMessage("AnErrorHasOccurred");
      const httpMsg = await parseHttpErrorMessage(ex);
      if (httpMsg) {
        message += " " + httpMsg;
      }
      notify({
        message: message,
        type: "error",
        displayTime: 5000
      }, { position, direction });
    }
  }
}

import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxTreeView = _resolveComponent("DxTreeView")!

  return (_openBlock(), _createElementBlock("div", {
    class: "dx-swatch-additional side-navigation-menu",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.forwardClick && $setup.forwardClick(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_DxTreeView, {
        ref: "treeViewRef",
        items: $setup.items,
        "key-expr": "path",
        "selection-mode": "single",
        "focus-state-enabled": false,
        "expand-event": "click",
        width: "100%",
        onItemClick: $setup.handleItemClick
      }, null, 8, ["items", "onItemClick"])
    ])
  ]))
}
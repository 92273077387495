import Api from "./api";
import fileDownload from "js-file-download";

export default {
  /**
   * A higher level function to upload a file for a specified entity/complex.
   * @param {string} path Directory path within the tenant's home folder to place the file.
   * @param {FileInfo} fileToUpload the FileInfo to upload. name must be specified.
   */
  async UploadFile(path, fileToUpload){
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          if (!path && !fileToUpload){
            reject("Missing input parameters");
            return;
          }
          if (!fileToUpload["name"]){
            reject("File has no name");
            return;
          }

          if (!(await this.CreateDirectory(path)))
          {
            console.error("failed to create directory " + path);
            reject("Failed to create directory");
            return;
          }
          
          if (!(await this.FileExist(path, fileToUpload.name))) {
            await this.CreateFile(path, fileToUpload).then(() => {
              console.log("file uploaded");
              resolve("File uploaded");
            }, (error) => {
              reject(error);
            });

          } else {
            reject("File already exists: " + fileToUpload.name);
            return;
          }

        } catch (error) {
          console.error(error);
          reject(error);
        }
      })();
      
    });
  },

  /**
   * Checks if the directory exists in the tenant's home directory.
   * @param {string} path Directory path within the tenant's home folder.
   * @returns promise resolved with true/false, or error if api call failed.
   */
  async DirectoryExist(path){
    return new Promise((resolve, reject) => {
      Api.post("/IfcFiles/DirectoryExist", { path: path }).then((result) => {
        resolve(result.data && result.data.exist === true);
      }, 
      (error) => {
        reject(error);
      });
    });
  },
  
  /**
   * Checks if the file exists in the specified directory in the tenant's home directory.
   * @param {string} path Directory path within the tenant's home folder.
   * @param {string} filename Filename to check.
   * @returns promise resolved with true/false, or error if api call failed.
   */
  async FileExist(path, filename){
    return new Promise((resolve, reject) => {
      Api.post("/IfcFiles/FileExist", { path: path, filename: filename }).then((result) => {
        resolve(result.data && result.data.exist === true);
      }, 
      (error) => {
        reject(error);
      });
    });
  },
  
  /**
   * Gets the file  in the specified directory in the tenant's home directory.
   * @param {string} path Directory path within the tenant's home folder.
   * @param {string} filename File to get.
   * @returns promise resolved with true/false, or error if api call failed.
   */
  async GetFile(path, filename){
    return new Promise((resolve, reject) => {
      Api.post("/IfcFiles/GetFile", { path: path, filename: filename }).then((result) => {
        fileDownload(result.data, filename);
      }, 
      (error) => {
        reject(error);
      });
    });
  },

  /**
   * Create the directory if it doesn't exists.
   * @param {string} path Directory path to create in the tenant's home folder.
   * @returns promise resolved with true/false, or error if api call failed.
   */
  async CreateDirectory(path){
    return new Promise((resolve, reject) => {
      Api.post("/IfcFiles/CreateDirectory", { path: path }).then((result) => {
        resolve(result.status === 200);
      }, 
      (error) => {
        reject(error);
      });
    });
  },
  
  /**
   * Does the actual file upload to on-premise.
   * @param {string} path Directory path within the tenant's home folder to place the file.
   * @param {Object} file the FileInfo to upload.
   */
  async CreateFile(path, file){
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("path", path);
      formData.append("file", file, file.name);
      Api.post("/IfcFiles/CreateFile", formData).then((response) => {
        resolve(response.status === 200);
      }).catch((error) => {
        reject(error);
      });
    });
  }
  
}

import router from "../router";
import b2cAuth from "./b2cAuth";
import basicAuth from "./basicAuth";
import kcAuth from "./keycloakAuth";
import ConfigValues from "../utils/configValues";
import { Permissions } from "@/authsrc/permissions";

/** main auth module/service which wraps around the currently active authenticate controller. 
 * All auth controllers should implement the same interface exposed by this main auth module for required auth functions in Asset app. */
export default  {
   
  /** checks if the user is logged in */
  loggedIn() {
    if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C"){
      return b2cAuth.loggedIn();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic"){
      return basicAuth.loggedIn();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC"){
      return kcAuth.loggedIn();
    } else {
      // unimplemented AUTH_TYPE
      return false;
    }
  },

  /** log out and redirects to the login page */
  async logOut() {
    router.push({
      path: "/login-page",
    });
    if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C"){
      b2cAuth.logOut();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic"){
      basicAuth.logOut();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC"){
       kcAuth.logOut();
    }
  },

  /** gets the current logged in user */
  async getUser() {
    let user = null;
    if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C"){
      user =b2cAuth.getUser();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic"){
      user = basicAuth.getUser();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC"){
      user = kcAuth.getUser();
    }

    try {
      return {
        isOk: user !== null && user !== undefined,
        data: user,
      };
    } catch {
      return {
        isOk: false,
        data: null
      };
    }
  },

  getTenantID() {
    if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C"){
      return b2cAuth.getTenantID();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "Basic"){
      return basicAuth.getTenantID();
    } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC"){
      return kcAuth.getTenantID();
    } else {
      return "";
    }
  },

  getPermissions(): Permissions {
    const authType = ConfigValues.Get("VUE_APP_API_AUTH_TYPE");
    switch(authType) {
      case "B2C":
        return b2cAuth.getPermissions();
      case "Basic":
        return {} as Permissions;
      case "KC":
        return kcAuth.getPermissions();
      default:
        return {} as Permissions;
    }
  },

  getRole() {
    const authType = ConfigValues.Get("VUE_APP_API_AUTH_TYPE");
    switch(authType) {
      case "B2C":
        return b2cAuth.getRole();
      case "Basic":
        return null;
      case "KC":
        return kcAuth.getRole();
      default:
        return null;
    }
  }  
};

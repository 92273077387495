<template>
  <div class="content-block">    
    <DataGrid />
  </div>
</template>
<script>
import DataGrid from "../components/data-grid/DataGrid.vue";
export default {
  components: {
    DataGrid
  },
  data() {
    return {};
  },
};
</script>
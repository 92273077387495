import { PublicClientApplication } from "@azure/msal-browser";
import store from "../store";
import ConfigValues from "../utils/configValues";
import router from "../router";
import { getPermissionsByRole, Permissions } from "./permissions"
import { UserRoleEnum } from "@swg/api";
import { formatMessage } from "devextreme/localization";

const InteractionRequiredAuthErrorName = "InteractionRequiredAuthError";
const BrowserAuthErrorName = "BrowserAuthError";

const msalConfig = {
  auth: {
    clientId: ConfigValues.Get("VUE_APP_B2C_CLIENT_ID"),
    authority: ConfigValues.Get("VUE_APP_B2C_AUTHORITY"),
    knownAuthorities: [ConfigValues.Get("VUE_APP_B2C_AUTHORITY_DOMAIN")],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2213
    iframeHashTimeout: 10000,
  },
};

const loginRequest = {
  prompt: "login",
};

export default {
    _auth: new PublicClientApplication(msalConfig),
    _user: null,
    _lastActive: null,
    _permissions: getPermissionsByRole(null),
    _role: null,
    /** checks if the user is logged into the B2C auth provider using the Azure msal-js library */
    loggedIn() {
      const account = this._auth.getActiveAccount();
      if (localStorage.getItem("lastActive") != null) {
        if (this.hasUserBeenIdle(localStorage.getItem("lastActive"))) {
          return false;
        }
      }

      localStorage.setItem("lastActive", String(Date.now()));
      if (account) {
        this.silentLogIn();
        this._user = { email: account.username };
        if (!this.loadRoleAndPermissions(account.idTokenClaims)) {
          this.logOut();
          return false;
        }
      }

      return !!this._user;
    },

    /** B2C login using the Azure msal-js library */
    async logIn() {
        let message: string = this.getTranslate("AuthenticationFailed");
        try {
          await this._auth.acquireTokenPopup(loginRequest).then((res) => {
          this._user = { email: res.account.username };
          if (!this.loadRoleAndPermissions(res.idTokenClaims)) {
            this._user = null;
            message = this.getTranslate("UserIsNotAcceptedYet");
            throw new Error(message);
          }
          this._auth.setActiveAccount(res.account);
          localStorage.setItem("lastActive", String(Date.now()));
          store.dispatch("setAccessToken", res.idToken);
        });
        return {
          isOk: true,
          data: this._user,
        };
      } catch {
        return {
          isOk: false,
          message: message
        };
      }
    },

    /** silent log in using the Azure msal-js library */
    async silentLogIn() {
      const activeAccount = this._auth.getActiveAccount();
      let isRefreshTokenExpired = true;

        if (activeAccount) {
            isRefreshTokenExpired = this.isAccessTokenExpired(
                activeAccount.idTokenClaims.exp
            );
        }

        if (localStorage.getItem("lastActive") != null) {
            if (this.hasUserBeenIdle(localStorage.getItem("lastActive"))) {
                return false;
            }
        }

        localStorage.setItem("lastActive", String(Date.now()));

      if (isRefreshTokenExpired || !store.getters.getAccessToken) {
        await this._auth
          .acquireTokenSilent({ account: activeAccount, forceRefresh: false })
          .then((res) => {
            store.dispatch("setAccessToken", res.idToken);
          })
            .catch((err) => {
                if (err.name === InteractionRequiredAuthErrorName ||
                    err.name === BrowserAuthErrorName) {
                    this.logOut();
                    router.push({
                        path: "/login-page",
                    });
                }
                console.error(err);
          });
      }
      return this.getAccessToken();
    },

    /** If the access token is expired */
    isAccessTokenExpired(exp) {
        const minutes =
            parseInt(ConfigValues.Get("VUE_APP_TRIGGER_REFRESH_TOKEN_MINUTES")) || 10;
        const now = Date.now();
        const expirationTime = exp * 1000 - minutes * 60 * 1000;

        return expirationTime <= now;
    },

    hasUserBeenIdle(exp) {
        const minutes = parseInt(ConfigValues.Get("VUE_APP_TRIGGER_REFRESH_TOKEN_MINUTES")) || 10;
        const now = Date.now();
        const expirationTime = parseInt(exp) + (minutes * 60 * 1000);

        return expirationTime <= now;
    },

    /** log out the user and calls the logout popup */
    async logOut() {
      this._user = null;
      this._auth.logoutPopup();
    },

    /** returns the current b2c user */
    getUser() {
      return this._user;
    },

    /** gets the access token */
    async getAccessToken() {
      return store.getters.getAccessToken;
    },

    /** gets the tenant-id from the token local storage key-value pair */
    getTenantID() {
      return Object.keys(localStorage)
          .find((key) => key.includes("idtoken"))
          .split(".")[1]
          .replace("-" + ConfigValues.Get("VUE_APP_B2C_TENANT_NAME"), "");
    },

    getPermissions(): Permissions {
      return this._permissions;
    },

    getRole() {
        return this._role;
    },

    loadRoleAndPermissions(idTokenClaims) {
      if (idTokenClaims.extension_Roles) {
        this._role = idTokenClaims.extension_Roles;
        this._permissions = getPermissionsByRole(UserRoleEnum[this._role]);
        return true;
      }
      return false;
    },
    getTranslate(text) {
      return formatMessage(text);
    },
};

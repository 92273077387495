
import {
  DxDataGrid,
  DxColumn
} from "devextreme-vue/data-grid";
import { formatMessage } from "devextreme/localization";
import { defineComponent } from "vue";
import { getFlattenedPermissions } from "../../authsrc/permissions"

export default defineComponent({
  components: { 
    DxDataGrid,
    DxColumn
  },  
  data() {
    return {
      permissions: getFlattenedPermissions()
    }
  },
  methods: {
    getTranslate(text) {
      return formatMessage(text);
    },
  }
})

/**
 * Parses http error message, returns empty string if error isn't of the right type
 * @param err Http-related error
 */
export async function parseHttpErrorMessage(err: any): Promise<string> {
  if (!err || !err.response || !err.response.status) {
    return "";
  }
  const response = err.response;

  let message = "";
  if (response.status === 422) {
    const responseBody = await response.json();
    if (Array.isArray(responseBody)) {
      for (const error of responseBody) {
        if (error?.errorMessage) {
          message += error.errorMessage + ", ";
        }
      }
      message = message.substring(0, message.length - 2) + ".";
    }
  }

  return message;
}


import { DxButton } from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import { defineComponent, PropType } from "vue";
import {
  DxValidator,
  DxPatternRule
} from "devextreme-vue/validator";
import { formatMessage } from "devextreme/localization";
import DxSelectBox from "devextreme-vue/select-box";
  
export default defineComponent({
  name: "ViewListName",
  components: {
    DxButton,
    DxTextBox,
    DxValidator,
    DxPatternRule,
    DxSelectBox
  },
  props: {
    acceptButtonText: {
      type: String,
      default: "OK"
    },
    viewListNames:{
      type: Array as PropType<string[]>,
      default: () => []
    },
    viewListSelected:{
      type: String,
      default:""
    }
  },
  emits: ["accepted","viewSelected","viewDeleted","viewUpdated","viewCleared"],
  data() {
    return {
      defaultValue: "",
      inputtedViewName: null,
      valueIsValid: null,
      seletedView: null,
      acceptButtonTextInternal: this.acceptButtonText,
      pattern: /^[a-zA-Z0-9[\]_\-+!@():,/\\ ]+$/,
      viewListsNamesInternal:this.viewListNames
    }
  },
  methods: {
    changeDefaultValue(value) {
      this.defaultValue = value;
    },
    raiseAccepted(name) {
      this.$emit("accepted", {
        viewName: name
      });
    },
    raiseViewSelected(args) {
      this.seletedView = args.value;
      this.$emit("viewSelected", {
        viewName: args.value
      });
    },
    raiseViewDeleted(args) {
      this.$emit("viewDeleted", {
        viewName: args
      });
    },
    raiseViewUpdated(args) {
      this.$emit("viewUpdated", {
        viewName: args
      });
    },
    raiseViewCleared(args) {
      this.$emit("viewCleared", {
        viewName: args
      });
    },
    onValueChanged(args) {
      this.inputtedViewName = args.value;
    },
    onValidated(args) {
      this.valueIsValid = args.isValid;
    },
    onSubmit() {
      if (this.valueIsValid) {
        this.raiseAccepted(this.inputtedViewName);
      }
    },
    onDelete() {
      if (this.seletedView) {
        this.raiseViewDeleted(this.seletedView);
      }
    },
    onUpdate() {
      if (this.seletedView) {
        this.raiseViewUpdated(this.seletedView);
      }
    },
    onClear() {
      if (this.seletedView) {
        this.raiseViewCleared(this.seletedView);
      }
    },
    getTranslate(text) {
      return formatMessage(text);
    },
  }
})
  
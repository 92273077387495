<template>
  <div>
    <h2 class="content-block">
      {{ profile }}
    </h2>

    <div class="content-block dx-card responsive-paddings">
      <span> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque diam mi, mattis vitae ante a, eleifend euismod mauris. Fusce sed neque ut orci efficitur ultrices eu quis mauris. </span>
    </div>
  </div>
</template>

<script>
import { formatMessage } from "devextreme/localization";
export default {
  data() {
    return {
      profile: this.getTitle(),
    };
  },
  methods: {
    getTitle() {
      return formatMessage("ReusableProfileLabel");
    },
  },
};
</script>

<style scoped lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>

import auth from "../authsrc/auth";
import b2cAuth from "../authsrc/b2cAuth";
import kcAuth from "../authsrc/keycloakAuth";
import ConfigValues from "./configValues";
import Api, { ViewerClient, middleware } from "./api";

export async function getB2CHeaders() {
    const token = await b2cAuth.silentLogIn();
    var m = new Map();
    m.set("Authorization", `Bearer ${token}`);
    return m;
}

async function getKeycloakHeaders() {
    var m = new Map();
    m.set("Authorization", `Bearer ${kcAuth.token}`);
    return m;
}

async function getBasicHeaders() {
    var m = new Map();
    m.set("x-tenant-id", auth.getTenantID() );
    return m;
}

export default {
    _initDone: false,

    /** sets up default Api interceptors providing the x-tenant-id for each Api request */
    setupApiInterceptors() {
        if (this._initDone)
            return;

        if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "B2C"){

            ViewerClient.options.getHeadersFunction = getB2CHeaders;
            middleware.getHeaders = getB2CHeaders;

            Api.interceptors.request.use(async config => {
                const token = await b2cAuth.silentLogIn();

                if (token) {
                    config.headers["Authorization"] =`Bearer ${token}`;
                    config.headers["x-tenant-id"] = auth.getTenantID();
                }

                return config;
            });
        } else if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC"){
            ViewerClient.options.getHeadersFunction = getKeycloakHeaders;
            middleware.getHeaders = getKeycloakHeaders;

            Api.interceptors.request.use(config => {
                if (kcAuth.token) {
                    config.headers["Authorization"] = `Bearer ${kcAuth.token}`;
                    config.headers["x-tenant-id"] = auth.getTenantID();
                }
                return config;
            });

        } else {
            ViewerClient.options.getHeadersFunction = getBasicHeaders;
            middleware.getHeaders = getBasicHeaders;

            Api.interceptors.request.use(config => {
                config.headers["x-tenant-id"] = auth.getTenantID();
                return config;
            });
        }

        this._initDone = true;
    }
}

<template>
  <div class="validation-results-container">
    <DxForm
      id="validationResultsForm"
      :col-count="2"
      :form-data="validationResultsProp"
    >
      <DxGroupItem :caption="getTranslate('ValidationResults')">
        <DxSimpleItem>
          <template #default>
            <DxProgressBar
              id="progress-bar-status"
              :class="{ complete: progressValue(validationResultsProp.totalPropertiesValidated, validationResultsProp.totalPropertyCount) >= 100 }"
              :min="0"
              :max="100"
              :value="progressValue(validationResultsProp.totalPropertiesValidated, validationResultsProp.totalPropertyCount)"
              width="100%"
            />
          </template>
        </DxSimpleItem>
        <DxSimpleItem>
          <template #default>
            <span style="font-size:16px;">
              {{ getTranslate('TotalPropertyCount') }}:&nbsp;
              {{ validationResultsProp.totalPropertiesValidated }}
              /
              {{ validationResultsProp.totalPropertyCount }}
            </span>
          </template>
        </DxSimpleItem>
        <DxSimpleItem>
          <template #default>
            <span style="font-size:16px;">
              {{ getTranslate('PropertyPass') }}:&nbsp;
              {{ validationResultsProp.propertyPass }}
            </span>
          </template>
        </DxSimpleItem>
        <DxSimpleItem>
          <template #default>
            <span style="font-size:16px;">
              {{ getTranslate('PropertyFail') }}:&nbsp;
              {{ validationResultsProp.propertyFail }}
            </span>
          </template>
        </DxSimpleItem>
        <DxSimpleItem>
          <template #default>
            <span style="font-size:16px;">
              {{ getTranslate('TotalObjectCount') }}:&nbsp;
              {{ validationResultsProp.totalObjectsValidated }}
              /
              {{ validationResultsProp.totalObjectCount }}
            </span>
          </template>
        </DxSimpleItem>
      </DxGroupItem>
      <DxGroupItem
        v-if="validationResultsProp.failsPerRuleset"
        :col-count="2"
        :caption="getTranslate('FailsPerRuleset')"
      >
        <DxSimpleItem
          data-field="failsPerRuleset.Unique"
          :editor-options="getButtonOptions('Unique')"
        >
          <DxLabel :text="getTranslate('Unique')" />
        </DxSimpleItem>
        <DxSimpleItem
          data-field="failsPerRuleset.Required" 
          :editor-options="getButtonOptions('Required')"
        >
          <DxLabel :text="getTranslate('Required')" />
        </DxSimpleItem>
        <DxSimpleItem
          :editor-options="getButtonOptions('IsFmGuid')"
          data-field="failsPerRuleset.IsFmGuid"
        >
          <DxLabel :text="getTranslate('IsFmGuid')" />
        </DxSimpleItem>
        <DxSimpleItem
          data-field="failsPerRuleset.DataType" 
          :editor-options="getButtonOptions('DataType')"
        >
          <DxLabel :text="getTranslate('DataType')" />
        </DxSimpleItem>
        <DxSimpleItem
          data-field="failsPerRuleset.IsInValues"
          :editor-options="getButtonOptions('IsInValues')"
        >
          <DxLabel :text="getTranslate('IsInValues')" />
        </DxSimpleItem>
      </DxGroupItem>
    </DxForm>
    <div
      v-if="failedObjects.length"
      class="failed-objects"
    >
      <span
        class="dx-form-group-caption"
      > {{ failedObjectsHeader }} </span>
      <DxDataGrid
        id="failedObjectsGrid"
        :key="failedGridKey"
        class="dx-form-group-content"
        :data-source="failedObjects"
        :show-borders="false"
        key-expr="validationResultId"
        :allow-column-resizing="true"
        :column-resizing-mode="'nextColumn'"
        :column-min-width="150"
        :column-auto-width="true"
        :repaint-changes-only="true"
        @cell-prepared="onCellPrepared"
      >
        <DxSearchPanel :visible="true" />
        <DxPaging :enabled="true" />
        <DxColumn 
          :allow-sorting="true"
          data-field="bimObjectExternalId"
          :caption="getTranslate('BimObjectExternalId')"
        />
        <DxColumn 
          v-if="validationResultsProp.fmGuid != '00000000-0000-0000-0000-000000000000'"
          :allow-sorting="true"
          data-field="fmGuid"
          :caption="getTranslate('ReusableFmGuidLabel')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="bimObjectName"
          :caption="getTranslate('CommonName')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="propertyName"
          :caption="getTranslate('PropertyName')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="parameterName"
          :caption="getTranslate('Parameter')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="resultText"
          :caption="getTranslate('ResultText')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="failingValue"
          :caption="getTranslate('FailingValue')"
        />
        <DxColumn 
          :allow-sorting="true"
          data-field="rulesetName"
          :caption="getTranslate('RulesetName')"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import store from "../../store";
import { formatMessage } from "devextreme/localization";
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxLabel
} from "devextreme-vue/form";
import { DxProgressBar } from "devextreme-vue/progress-bar";

    
import notify from "devextreme/ui/notify";

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPaging,
} from "devextreme-vue/data-grid";
import { validationAPI } from "@/utils/api";

export default {
  name: "ValidationResults",
  components: {
    DxForm,
    DxSimpleItem,
    DxSearchPanel,
    DxGroupItem,
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxLabel,
    DxProgressBar
  },
  props:  {
    validationResultsProp: {
      type:  Object,
      required: true,
    }
  },
  emits: [],
  data() {
    return {
      results: [],
      resultsGridKey: 0,
      failedObjects: [],
      failedObjectsHeader: "",
    }
  },
  methods: {
    onCellPrepared(e) {
      if(e.rowType === "data" && e.column.dataField === "fmGuid") {
        if(e.data.fmGuid == "00000000-0000-0000-0000-000000000000"){
          e.value = "";
        }
      }
    },
    getTranslate(text) {
      return formatMessage(text);
    },
    progressValue(received, totalProgress){
      let total = (received / totalProgress)*100;


      
      return Math.floor(total);
    },
    showDetails(rule) {
      store.dispatch("setLoader", true);
      this.failedObjectsHeader = this.getTranslate("FailedObjects") + ": " + rule;
      validationAPI.getValidationResults({
                    
        revisionId: store.getters.getSelectedModel.revisionId,
                      
        modelId: store.getters.getSelectedModel.bimObjectId,
        ruleSet: rule
                    
      })
        .then((response) => {
          this.failedObjects = response;
          store.dispatch("setLoader", false);
        })
        .catch((error) => {
          notify(formatMessage("GetFilesError"), "error", 3000);
          console.error(error);
        });
    }, 
    getButtonOptions(ruleName) {
      this.failedObjectsHeader = this.getTranslate("FailedObjects") + ": " + ruleName;
      const editOptions = {
        buttons: [{
          name: "expand",
          location: "after",
          options: {
            stylingMode: "text",
            icon: "increaseindent",
            onClick: () => {
              this.showDetails(ruleName);
            },
          },
        }],
      }
      if (!this.validationResultsProp.failsPerRuleset[ruleName]) {
        Object.assign(editOptions, { value: 0 })
        Object.assign(editOptions.buttons[0].options, { disabled: true })
      }
      else{
        Object.assign(editOptions, { value: this.validationResultsProp.failsPerRuleset[ruleName] })
        Object.assign(editOptions.buttons[0].options, { disabled: false })
      }
      return editOptions;
    }
  }
}
</script>
<style scoped>
  #validationResultsForm {
    width:100%;
    padding: 12px;
  }
  #validationResultsForm .dx-form-group .dx-box-item .dx-texteditor {
      border: none;
  }
  #validationResultsForm .dx-texteditor-input {
      pointer-events: none;
  }
  .failed-objects {
    padding: 12px;
  }
  #failedObjectsGrid {
    margin-top: 5px;
    max-height: calc(100vh - 320px);
  }
  #failedObjectsGrid .dx-datagrid {  
    background-color:transparent;  
  }
</style>

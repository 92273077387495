<template>
  <div class="content-block">
    <AssetViewer
      :src="source"
      :client="client"
    />
  </div>
</template>

<script>
import { AssetViewer } from "@swg/lib";
import { ViewerClient } from "../utils/api";


export default {
  components: {
    AssetViewer,
  },
  data() {
    return {
      source: [],
      client: ViewerClient,
    };
  },
  mounted() {
    const models = this.$route.query.models;

    console.log("VIEWER");
    console.log(models);

    if (!Array.isArray(models)) {
      this.source = [{ modelId: models }];
    } else {
      this.source = [];
      models.forEach((element) => {
        this.source.push({ modelId: element });
      });
    }
  },
  methods: {},
};
</script>

<style scoped>
.content-block {
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1 0 auto;
  margin: 0px;
  border: 1px dotted dodgerblue;
}

.viewer {
  width: 100%;
  background-color: red;
}
</style>

import { AssetDBClient } from "@swg/lib";
import {
  BimServiceApi,
  Configuration,
  DefaultApi,
  FileServiceApi,
  IfcServiceApi,
  LogServiceApi,
  ParameterServiceApi,
  PropertyMappingServiceApi,
  PublishedDataServiceApi,
  UserServiceApi,
  ValidationServiceApi,
  ViewListServiceApi,
} from "@swg/api";

import axios from "axios";
import notify from "devextreme/ui/notify";
import ConfigValues from "./configValues";

const Api = axios.create({
    baseURL: ConfigValues.Get("VUE_APP_API_URL")
});


export const middleware = {

    getHeaders: null,

    async pre(context) {
        if( this.getHeaders != null )
        {
            const headers = await this.getHeaders();
            headers.forEach( (value, key) => {
                context.init.headers[key] = value;});

        }

    },
};


const config = new Configuration({
    basePath: ConfigValues.Get("VUE_APP_API_URL"),
    middleware: [middleware]
});

export const bimAPI = new BimServiceApi(config);
export const defaultAPI = new DefaultApi(config);
export const propertyMappingAPI = new PropertyMappingServiceApi(config);
export const parameterAPI = new ParameterServiceApi(config);
export const publishedDataAPI = new PublishedDataServiceApi(config);
export const validationAPI = new ValidationServiceApi(config);
export const fileAPI = new FileServiceApi(config);
export const logAPI = new LogServiceApi(config);
export const ifcAPI = new IfcServiceApi(config);
export const userAPI = new UserServiceApi(config);
export const viewListAPI = new ViewListServiceApi(config);

export const ViewerClient = new AssetDBClient( {
    baseUrl: ConfigValues.Get("VUE_APP_API_URL")
});


Api.interceptors.response.use((response) => {
    return response;
}, (err) => {
    console.error(err);
    notify(err, "error", 3000);
});

export default Api;


import { DxTreeView } from "devextreme-vue/tree-view";
import { sizes } from "@/utils/media-query";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { formatMessage } from "devextreme/localization";
import { Item } from "devextreme/ui/tree_view";

export default {
  components: {
    DxTreeView
  },
  props: {
    compactMode: Boolean
  },
  emits: ["click"],
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const navigation: Item[] = [
      {
        text: formatMessage("SideNavMenuHomeLabel"),
        path: "/home",
        icon: "home",
      },
      {
        text: formatMessage("SideNavMenuTasksFiles"),
        path: "/ifcfiles",
        icon: "file",
      },
      {
        text: formatMessage("SideNavMenuTasksSettings"),
        path: "/settings",
        icon: "preferences",
      },
    ];

    const isLargeScreen = sizes()["screen-large"];
    const items: Item[] = navigation.map((item) => {
      if(item.path && !(/^\//.test(item.path))){ 
        item.path = `/${item.path}`;
      }
      return { ...item, expanded: isLargeScreen } 
    });

    const treeViewRef = ref(null);

    function forwardClick (...args) {
      context.emit("click", args);
    }

    function handleItemClick(e) {
      if (!e.itemData.path || props.compactMode) {
        return;
      }
      router.push(e.itemData.path);

      const pointerEvent = e.event;
      pointerEvent.stopPropagation();
    }

    function updateSelection () {
      if (!treeViewRef.value || !treeViewRef.value.instance) {
        return;
      }

      treeViewRef.value.instance.selectItem(route.path);
      treeViewRef.value.instance.expandItem(route.path);
    }

    onMounted(() => {
      updateSelection();
      if (props.compactMode) {
        treeViewRef.value.instance.collapseAll();
      }
    });


    watch(
      () => route.path,
      () => {
        updateSelection();
      }
    );

    watch(
      () => props.compactMode,
      () => {
        if (props.compactMode) {
          treeViewRef.value.instance.collapseAll();
        } else {
          updateSelection();
        }
      }
    );

    return {
      treeViewRef,
      items,
      forwardClick,
      handleItemClick,
      updateSelection
    };
  },
};

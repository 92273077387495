export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Files",
    path: "/ifcfiles",
    icon: "file"
  },
  {
    text: "Settings",
    path: "/settings",
    icon: "settings"
  },
  {
    text: "Mappings",
    path: "/mappings",
    icon: "fieldchooser"
  },
  ];

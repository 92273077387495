import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createApp } from "vue";
// @ts-ignore
import router from "./router";

import App from "./App.vue";
// @ts-ignore
import store from "./store";
// @ts-ignore
import KC from "./authsrc/keycloakAuth";
// @ts-ignore
import ConfigValues from "./utils/configValues";

const bootstrap = (...plugins: any[]) => {
  document.title = ConfigValues.Get("VUE_APP_ASSETDB_TITLE");
  if (window.location.protocol === "https:" && !ConfigValues.onPremise) {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: ConfigValues.Get("VUE_APP_INSTRUMENTATION_KEY"),
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
      }
    });

    appInsights.loadAppInsights();
  }

  const app = createApp(App);
  app.use(router);
  app.use(store);
  if (plugins && plugins.length > 0)
    plugins.forEach(plugin => {
      app.use(plugin);
    });

  app.config.globalProperties.$appInfo = { "title": ConfigValues.Get("VUE_APP_ASSETDB_TITLE") || "QFM BIMi / Asset+" };

  app.mount("#app");
};


if (ConfigValues.Get("VUE_APP_API_AUTH_TYPE") === "KC") {
  KC.setup().then((keycloak: any) => {
    bootstrap(keycloak);
  }).catch((e: any) => {
    if (e) {
      console.error(e);
    }
    console.error("Error during setup of Keycloak. This can be because of a faulty configured Keycloak service or configuration changes. Access Type must be public for the client for the Asset frontend.");
  });
} else {
  bootstrap();
}

import { ShareServiceClient } from "@azure/storage-file-share";
import notify from "devextreme/ui/notify";
import ConfigValues from "./configValues";
import fileDownload from "js-file-download";

export async function DownloadFileFromAzure(tenantId, sas, filePath, fileName) {
  const serviceClientWithSAS = new ShareServiceClient(sas);
  let directoryClient = serviceClientWithSAS.getShareClient(
    ConfigValues.Get("VUE_APP_FILE_STORAGE_SHARE_NAME")
  ).rootDirectoryClient;
  
  directoryClient = directoryClient.getDirectoryClient(tenantId);
  directoryClient = directoryClient.getDirectoryClient(filePath);

  if (fileName !== undefined) {
    const fileClient = directoryClient.getFileClient(
      fileName
    );

    if ((await fileClient.exists())) {
      const downloadFileResponse = await fileClient.download();
      fileDownload(await downloadFileResponse.blobBody, fileName);
    } else {
      notify(
        "File does not exist: " + fileName,
        "warning",
        5000
      );
      return false;
    }
  }
  else{
      return false;
  }
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a482621e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-block" }
const _hoisted_2 = { class: "color-template" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DxEditing = _resolveComponent("DxEditing")!
  const _component_DxSearchPanel = _resolveComponent("DxSearchPanel")!
  const _component_DxPaging = _resolveComponent("DxPaging")!
  const _component_DxPager = _resolveComponent("DxPager")!
  const _component_DxFilterPanel = _resolveComponent("DxFilterPanel")!
  const _component_DxHeaderFilter = _resolveComponent("DxHeaderFilter")!
  const _component_DxFilterRow = _resolveComponent("DxFilterRow")!
  const _component_DxColumn = _resolveComponent("DxColumn")!
  const _component_DxButton = _resolveComponent("DxButton")!
  const _component_DxItem = _resolveComponent("DxItem")!
  const _component_DxToolbar = _resolveComponent("DxToolbar")!
  const _component_DxDataGrid = _resolveComponent("DxDataGrid")!
  const _component_DxToolbarItem = _resolveComponent("DxToolbarItem")!
  const _component_DxLabel = _resolveComponent("DxLabel")!
  const _component_DxRequiredRule = _resolveComponent("DxRequiredRule")!
  const _component_DxStringLengthRule = _resolveComponent("DxStringLengthRule")!
  const _component_DxColorBox = _resolveComponent("DxColorBox")!
  const _component_DxLookup = _resolveComponent("DxLookup")!
  const _component_DxScrolling = _resolveComponent("DxScrolling")!
  const _component_DxForm = _resolveComponent("DxForm")!
  const _component_DxPopup = _resolveComponent("DxPopup")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString($options.getTranslate("ParameterGroups")), 1),
    _createVNode(_component_DxDataGrid, {
      id: "parametersGroupsGrid",
      "data-source": $data.dataSource,
      "show-borders": false,
      "column-resizing-mode": 'nextColumn',
      "column-min-width": 150,
      "column-auto-width": true,
      onToolbarPreparing: $options.onToolbarPreparing
    }, {
      colorTemplate: _withCtx(({ data }) => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            style: _normalizeStyle({'background-color': data.key.color })
          }, null, 4),
          _createTextVNode(" " + _toDisplayString(data.key.color), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_DxEditing, {
          "allow-adding": $data.permissions.parameterGroups.canCreate,
          "allow-updating": $data.permissions.parameterGroups.canEdit,
          "allow-deleting": $data.permissions.parameterGroups.canDelete,
          mode: "popup"
        }, null, 8, ["allow-adding", "allow-updating", "allow-deleting"]),
        _createVNode(_component_DxSearchPanel, { visible: true }),
        _createVNode(_component_DxPaging, { "page-size": 25 }),
        _createVNode(_component_DxPager, {
          "allowed-page-sizes": [25, 50, 100, 200],
          "display-mode": "full",
          "show-page-size-selector": true,
          "show-info": true,
          "show-navigation-buttons": true,
          visible: true,
          "info-text": $options.getTranslate('ReusablePaginationLabel')
        }, null, 8, ["info-text"]),
        _createVNode(_component_DxFilterPanel, { visible: true }),
        _createVNode(_component_DxHeaderFilter, {
          "allow-search": true,
          visible: true
        }),
        _createVNode(_component_DxFilterRow, {
          visible: true,
          "apply-filter": 'auto'
        }),
        _createVNode(_component_DxColumn, {
          "data-field": "name",
          caption: $options.getTranslate('ReusableNameLabel')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, {
          "data-field": "color",
          "cell-template": "colorTemplate",
          caption: $options.getTranslate('Color')
        }, null, 8, ["caption"]),
        _createVNode(_component_DxColumn, { type: "buttons" }, {
          default: _withCtx(() => [
            _createVNode(_component_DxButton, {
              name: "edit",
              onClick: $options.editClick
            }, null, 8, ["onClick"]),
            _createVNode(_component_DxButton, { name: "delete" })
          ]),
          _: 1
        }),
        _createVNode(_component_DxToolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_DxItem, {
              options: $data.addParameterButtonOptions,
              name: "addRowButton",
              "show-text": "always"
            }, null, 8, ["options"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data-source", "onToolbarPreparing"]),
    _createVNode(_component_DxPopup, {
      ref: "popup",
      visible: $data.popupVisible,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (($data.popupVisible) = $event)),
      "show-title": true,
      title: $data.labels.createEditParameterGroupLabel,
      "max-width": 1200,
      "max-height": 700,
      "hide-on-outside-click": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DxToolbarItem, {
          widget: "dxButton",
          location: "after",
          toolbar: "bottom",
          options: {
          text: $options.getTranslate('Save'),
          onClick: $options.confirmClick
        }
        }, null, 8, ["options"]),
        _createVNode(_component_DxToolbarItem, {
          widget: "dxButton",
          location: "after",
          toolbar: "bottom",
          options: {
          text: $options.getTranslate('ReusableCancelButtonLabel'),
          onClick: $options.cancelClick
        }
        }, null, 8, ["options"]),
        _createVNode(_component_DxForm, {
          ref: "form",
          "form-data": $data.formData,
          "onUpdate:form-data": _cache[0] || (_cache[0] = ($event: any) => (($data.formData) = $event)),
          "label-location": "top",
          "show-colon-after-label": true,
          "col-count": 12
        }, {
          default: _withCtx(() => [
            _createVNode(_component_DxItem, {
              "data-field": "name",
              "col-span": 6
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLabel, {
                  text: $options.getTranslate('ReusableNameLabel')
                }, null, 8, ["text"]),
                _createVNode(_component_DxRequiredRule, {
                  message: $options.getTranslate('CreateEditParameterGroupNameRequiredMessage')
                }, null, 8, ["message"]),
                _createVNode(_component_DxStringLengthRule, {
                  max: 50,
                  message: $data.labels.nameLengthValidationMessage
                }, null, 8, ["message"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxItem, { "col-span": 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLabel, {
                  text: $options.getTranslate('Color')
                }, null, 8, ["text"]),
                _createVNode(_component_DxColorBox, {
                  value: $data.selectedColor,
                  "on-value-changed": $options.colorValueChanged
                }, null, 8, ["value", "on-value-changed"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxItem, { "col-span": 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLabel, {
                  text: $options.getTranslate('AvailableParameters')
                }, null, 8, ["text"]),
                _createVNode(_component_DxLookup, {
                  items: $data.availableParameters,
                  "value-expr": "parameterId",
                  "display-expr": "name",
                  "search-enabled": true,
                  "on-value-changed": $options.availableParameterChanged,
                  value: $data.selectedParameterId
                }, null, 8, ["items", "on-value-changed", "value"])
              ]),
              _: 1
            }),
            _createVNode(_component_DxItem, {
              "col-span": 3,
              "item-type": "button",
              "horizontal-alignment": "left",
              "vertical-alignment": "bottom",
              "button-options": $options.addBtnOptions
            }, null, 8, ["button-options"]),
            _createVNode(_component_DxItem, {
              "col-span": 3,
              "item-type": "empty"
            }),
            _createVNode(_component_DxItem, { "col-span": 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_DxLabel, {
                  text: $options.getTranslate('Parameters')
                }, null, 8, ["text"]),
                _createVNode(_component_DxDataGrid, {
                  id: "parametersGrid",
                  "data-source": $data.assignedParameters,
                  "key-expr": "parameterId",
                  columns: ['name'],
                  "show-borders": true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_DxEditing, {
                      "allow-adding": false,
                      "allow-updating": false,
                      "allow-deleting": true,
                      mode: "popup"
                    }),
                    _createVNode(_component_DxScrolling, { mode: "infinite" }),
                    _createVNode(_component_DxColumn, {
                      "data-field": "name",
                      caption: $options.getTranslate('ReusableNameLabel')
                    }, null, 8, ["caption"]),
                    _createVNode(_component_DxColumn, { type: "buttons" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_DxButton, {
                          name: "delete",
                          onClick: $options.deleteAssignedParameter
                        }, null, 8, ["onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["data-source"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["form-data"])
      ]),
      _: 1
    }, 8, ["visible", "title"])
  ]))
}